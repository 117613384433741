.sub-container {
  margin-top: 50px;
  min-width: 200px;
  padding: 10px;
  max-height: calc(100vh - 80px);
  overflow: auto;
}

.checkBill-btn-get-started {
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  padding: 4px;
  border-radius: 5px;
  transition: 0.5s;
  color: #fff;
  background: #ce2f2f;
  cursor: pointer;
  text-decoration: none;
  position: relative;
}
.checkBill-btn-get-started:hover {
  color: black;
}

.big-grey-div {
  background: rgba(0, 0, 0, 0.3);
  display: block;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 10000;
}

.modify-resa-div {
  background: #fff;
  border-radius: 5px;
  box-shadow: 1px 1px 10px #000;
  display: none;
  flex-direction: column;
  left: calc(50% - 300px);
  position: absolute;
  top: 100%;
  width: 600px;
  z-index: 10001;
  height: 600px;
  text-align: center;
  overflow-y: auto;
}
.modify-resa-div label {
  margin-top: 10px;
}

.modify-resa-div input {
  margin: 0px auto 20px;
  background-color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 1px 1px 10px gray;
  min-height: 20px;
  min-width: 50px;
}
.modify-resa-div select {
  margin: 0px auto 20px;
  background-color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 1px 1px 10px gray;
}

#spinner-modal {
  /* display: none; */
  position: fixed;
  z-index: 20000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.2) url("../assets/spinner.gif") 50% 50% no-repeat;
}
