.div-select .select {
  background-color: #fff;
  border: 2px solid gray;
  border-radius: 50px;
  box-shadow: 1px 1px 10px #80808070;
  margin: 10px;
  padding: 0 0 0 10px;
  width: calc(100% - 20px);
  max-width: 300px;
}

.div-select {
  text-align: center;
}

.table-container {
  max-height: 80%;
  overflow-y: auto;
  width: 320px;
  margin: auto;
  max-width: 100%;
}
.table {
  border: 1px solid gray;
  border-radius: 10px;
  max-height: 80%;
  overflow-x: hidden;
  /* overflow-y: scroll; */
  margin: 0 auto;
}
.table ul {
  margin: 0;
  border-top: unset;
  border-bottom: 1px solid gray;
  cursor: pointer;
  padding-left: 10px;
}

.table ul:hover {
  background-color: #d7d7d77d;
  scale: 1.02;
}

.table-with-select {
  max-height: 600px;
  height: 100%;
}
