.tableTest-form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.tableTest-form input {
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  margin-left: 10px;
}
/* Set table width and font */
table {
  width: 100%;
  font-family: "Arial", sans-serif;
  table-layout: fixed; /* prevents columns from adjusting to the size of the content */
}

/* Add border to table cells */
td,
th {
  border: 1px solid #d3d3d3;
  padding: 8px;
  overflow: hidden; /* hides text that overflows the cell */
  text-overflow: ellipsis; /* adds "..." when text overflows the cell */
  /* min-width: 80px; sets minimum width for the cells */
  resize: both;
  /* height: 50px; */
  white-space: nowrap;
  border-top: 0.5px solid #d3d3d3;
  border-bottom: 0.5px solid #d3d3d3;
  border-left: 0.2px solid #d3d3d3;
  border-right: 0.2px solid #d3d3d3;
}

/* Add hover effect to table rows */
tr:hover {
  background-color: #f2f2f2;
}

/* Add background color to table header */
thead {
  background-color: #f2f2f2;
}

/* Add bold font weight to table header */
th {
  /* font-weight: bold; */
  cursor: grab;
  color: #464646;
}
th:active {
  cursor: grabbing;
}
th::after {
  /* styles for the resizer */
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 10px;
  height: 100%;
  cursor: col-resize;
  pointer-events: none;
}

/* Add scrollbar to table if necessary */
tbody {
  overflow-y: scroll;
  max-height: 500px;
}
/* Remove the horizontal scrollbar */
thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
