.EDLbanner {
  width: 100%;
  height: 45px;
  background-color: #f4af24;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  /* box-shadow: 5px 5px 10px black; */
  opacity: 0.95;
  display: flex;
  justify-content: space-between;
  scroll-behavior: smooth;
}

.EDLlogo-EDL-fr-div {
  height: 45px;
  max-width: 150px;
  position: relative;
  left: 30px;
  align-content: center;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
}

.EDLlogo-EDL-fr-div img {
  width: auto;
  margin: auto;
  height: 45px;
}

.EDLheader-link {
  display: none;
  margin-top: auto;
  margin-bottom: auto;
  /* margin-right: 30px; */
  flex-wrap: wrap;
}
.EDLheader-link a {
  margin: 5px;
  padding-left: 10px;
}

.EDLmenuNav {
  /* height: 55px; */
  /* max-width: 150px; */
  /* position: relative; */
  /* left: 25vw; */
  /* align-content: center;
    margin-top: auto;
    margin-bottom: auto; */
  /* border-right: 1px solid white; */
}
.EDLmenuNav ul {
  display: flex;
  /* height: 45px; */
  margin: unset;
  padding: 0;
}
.EDLmenuNav li {
  list-style: none;
  /* margin: 30px; */
  height: 35px;
  line-height: 35px;
  margin: 5px;
}
.dropdown-section-ul {
  list-style: none;
  /* margin: 30px; */
  height: 35px;
  line-height: 35px;
  margin: 5px;
}
.dropdown-section-li {
  list-style: none;
  /* margin: 30px; */
  height: 35px;
  line-height: 35px;
  margin: 5px;
}
.EDLmenuNav a {
  text-decoration: none;
  color: #555;
  border-radius: 100px;
  transition: 0.5s;
  padding: 5px;
}

.EDLmenuNav a:hover {
  /* background: rgba(24, 30, 56, 1); */
  /* box-shadow: 1px 1px 10px black; */
  color: black;
}

.EDLmenuNavAndContact {
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 30px;
  flex-wrap: wrap;
}

.hamburgerIcon {
  display: none;
  z-index: 10000;
}

.hamburgerIcon img {
  width: 50px;
  z-index: 10000;
}

@media only screen and (max-width: 750px) {
  .banner {
    width: 100vw;
  }
  .header-link {
    display: none;
  }
  .EDLmenuNav {
    z-index: 1000;
    border: none;
  }

  .EDLmenuNav ul {
    flex-direction: column;
    background: linear-gradient(rgb(25, 65, 90), rgb(24, 30, 56));
    border-radius: 40px;
    padding: 10px;
    height: auto;
    /* display: none; */
  }

  .hamburgerIcon {
    display: block;
    cursor: pointer;
  }
}

@media only screen and (orientation: landscape) and (max-width: 900px) {
  .hamburgerIcon {
    display: block;
    cursor: pointer;
  }
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f4af24;
  min-width: 300px;
  padding: 12px 16px;
  z-index: 5;
  overflow-y: auto;
  max-height: 80vh;
}
.dropdown-content ul {
  display: inline-block;
  margin: unset;
  padding: 0;
}
.dropdown {
  color: white;
  position: relative;
  display: inline-block;
}
.dropdown:hover .dropdown-content {
  display: block;
}
