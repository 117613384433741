/* .container-Home {
  height: auto;
  overflow: unset;
  display: flex;
  min-height: 570px;
} */
body {
  font-family: "Montserrat-medium";
}

/* .ticket-EDL-home-title {
  color: #000;
} */

.ticket-container-Home {
  /* overflow-y: hidden; */
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  /* height: 100vh; */
  width: inherit;
  /* overflow: hidden; */
}
.ticket-submitForm {
  background: transparent;
  opacity: 0.8;
  border-radius: 5px;
  padding: 20px;
  transition: 0.5s;
  box-shadow: 1px 5px 10px gray;
  color: black;
  display: flex;
  flex-direction: column;

  margin: 20px;
  padding: 5%;
  text-align: left;
  font-size: 14px;
  margin-bottom: 2em;
  line-height: 30px;
  overflow-x: hidden;
}
.ticket-submitForm p {
  color: black;
  text-align: left;
  font-size: 14px;
  line-height: 30px;
}

.ticket-submitForm textarea {
  max-width: 35vw;
  min-width: 80%;
  width: 100%;
  max-height: 300px;
  min-height: 100px;
  height: 100px;
  margin: auto;
  margin-bottom: 10px;
  border: 1px solid gray;
}

.ticket-submitForm label {
  margin: 10px;
}

.ticket-submitForm select {
  cursor: pointer;
  background-color: white;
  border-radius: 3px;
  border: 1px solid gray;
}

.ticket-submitForm input,
textarea {
  border-radius: 3px;

  border: 1px solid gray;
  background-color: white;
}

/* .submitForm textarea {
  border-radius: 3px;
  border: 1px solid #000;
} */

.ticket-inputForm {
  width: 100%;
  margin: auto;
  margin-bottom: 10px;
}
#submitFormDateInput {
  cursor: pointer;
}

.formAlert {
  color: red;
}
.ticket-background-div {
  position: absolute;
  width: 100vw;
  min-height: 100vh;
  max-height: 880px;
  right: 0;
  top: 0;
  overflow: hidden;
  z-index: 1;
  /* height: 100vh; */
  /* box-shadow: 1px 1px 20px grey; */
}

.ticket-background-img {
  width: 120%;
  height: 100%;
  opacity: 1;
  z-index: 0;
  box-shadow: 1px 1px 20px grey;
}

.ticket-blog-home-title {
  /* z-index: 100;
  font-family: "Montserrat-medium";
  color: black;
  margin-right: auto;
  margin-left: auto;
  margin-top: 80px;
  width: 80%;
  display: flex;
  text-shadow: 1px 5px 10px; */
  color: #000;
  display: flex;
  font-family: Montserrat-medium;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  text-shadow: 1px 5px 10px;
  width: 90%;
  z-index: 100;
  justify-content: space-around;
}
.ticket-blog-home-title-div {
  border-bottom: 1px solid black;
}
.titles-tickets {
  margin: auto;
  margin-right: 20px;
  margin-left: 20px;
}
.ticket-blog-home-title p {
  font-size: 14px;
  font-weight: normal;
  text-align: unset;
  text-shadow: none;
  margin-bottom: 0;
}
.ticket-blog-home-title h1 {
  font-size: 40px;
  margin: 0;
  text-align: center;
  font-weight: 1;
  text-shadow: none;
  font-family: "Montserrat-medium";
}
.ticket-blog-home-title h2 {
  font-weight: 10;
  text-shadow: 2px 2px 10px black;
  text-align: center;
}

/* .ticket-mid-home-div-logo {
  z-index: 10;
  position: relative;
  width: 30%;

  left: 5%;

  display: flex;
}
.mid-home-div-logo img {
  width: 100%;
  opacity: 0.25;
  margin: auto;
  max-width: 500px;
} */

.ticket-btn-browse {
  font-size: 14px;
  font-weight: 200;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 16px;
  border-radius: 5px;
  transition: 0.5s;
  color: #fff;
  background: #f4af24;
  cursor: pointer;
  text-decoration: none;
  border: none;
}

.ticket-btn-browse:hover {
  /* background: rgb(163, 59, 59); */
  color: black;
  box-shadow: 1px 1px 10px black;
}

.dropZone {
  border: 2px dashed black;
  margin: 40px;
  transition: 0.5s;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
}

.dropZone img {
  height: 50px;
  z-index: -1000;
  position: relative;
}
.dropZone p {
  margin: 0;
  text-shadow: none;
}
#getFile {
  display: none;
}

.btn-get-started {
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 16px;
  border-radius: 5px;
  transition: 0.5s;
  color: #fff;
  background: #f4af24;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  z-index: -1000;
}
#ticket-btn-get-started {
  background: #f4af24;
  position: relative;
  left: calc(50% - 50px);
  width: 100px;
  text-align: center;
  border: none;
  z-index: 100;
}

.btn-get-started:hover {
  color: black;
  /* box-shadow: 1px 1px 10px rgb(7, 7, 7); */
}

.drop-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 800px;
  height: 200px;
  border: 4px dashed #4aa1f3;
}

#spinner-modal {
  /* display: none; */
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.2) url("../assets/spinner.gif") 50% 50% no-repeat;
}

#loading-long-text {
  position: fixed;
  top: 55%;
  z-index: 2001;
  margin: auto;
  left: calc(50% - 150px);
  width: 300px;
  font-size: 30px;
  text-shadow: 5px 5px 15px #000;
  opacity: 0;
  color: #fff;
}

#backToPortail {
  z-index: 1000;
}

#containerMyTickets {
  font-family: "Montserrat-medium";
  z-index: 100;
  position: relative;
  text-align: center;
}

.ulTicket {
  display: flex;
  flex-direction: column;
}
.ticketList {
  width: 50%;
  margin: auto;
  text-align: left;
  list-style: none;
  cursor: pointer;
}

.ulTickets:hover {
  background-color: unset;
  color: unset;
}

.ticket-subDivForm1 {
  display: flex;
  width: 100%;
}
.ticket-subDivForm2 {
  width: 45%;
  margin: auto;
  margin-right: 10px;
  margin-left: 10px;
}
.ticket-subDivForm2 p {
  font-size: 14px;
  font-weight: normal;
  text-align: unset;
  text-shadow: none;
  margin-bottom: 0;
}

.planning {
  z-index: 2000;
  margin-right: 10%;
  width: 80%;
  margin-left: 10%;
}

.standard-table {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  table-layout: fixed;
  width: 94%;
  height: 500px;
  overflow: hidden;
  margin: 5px 3%;
  box-shadow: 1px 5px 20px 0px gray;
  border-spacing: 0;
  border-collapse: collapse;
  font-weight: 400;
  text-align: left;
}
.expanded-record {
  position: fixed;
  width: 850px;
  height: 50vh;
  top: calc(20%);
  background-color: white;
  left: calc(50% - 425px - 25px);
  box-shadow: 1px 1px 10px 1px gray;
  border-radius: 5px;
  padding: 50px;
  overflow: auto;
  border: 2px solid gray;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}

@font-face {
  font-family: "Kollectif";
  src: url(../fonts/kollektif/Kollektif.ttf); /* IE9 Compat Modes */
  font-display: swap;
}
@font-face {
  font-family: "Montserrat-medium";
  src: url(../fonts/Montserrat/static/Montserrat-Medium.ttf); /* IE9 Compat Modes */
  font-display: swap;
}
@font-face {
  font-family: "DoHyeon";
  src: url(../fonts/Do_Hyeon/DoHyeon-Regular.ttf); /* IE9 Compat Modes */
  font-display: swap;
}

@media only screen and (max-width: 1160px) {
  .mid-home-div-logo {
    position: absolute;
    top: 15%;
  }
  .blog-home-title {
    left: 40%;
  }

  .blog-home-title h1 {
    font-size: 80px;
  }
}

@media only screen and (max-width: 650px) {
  .mid-home-div-logo {
    display: none;
  }
  .ticket-blog-home-title {
    top: 20%;
    /* left: 15%; */
    width: 70%;
    max-width: none;
    flex-direction: column;
  }

  .blog-home-title h1 {
    font-size: 60px;
  }

  .blog-home-title h2 {
    margin-top: 50px;
    font-size: 24px;
  }
  .titles-tickets {
    width: 90%;
  }
  .ticket-submitForm {
    width: 100%;
  }
  .ticket-background-img {
    display: none;
  }
  .background-div {
    height: 100vh;
    width: inherit;
  }
  .background-div img {
    height: 100vh;
    width: inherit;
    overflow: hidden;
  }

  /* .home-button {
      position: absolute;
      right: 10%;
      top: 80%;
      margin-top: 0;
      z-index: 10;
      width: 200px;
      left: unset;
    } */
  .container-Home {
    height: 100vh;
    width: inherit;
    overflow: unset;
    display: flex;
    min-height: 570px;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .mid-home-div-logo {
    display: none;
  }
  .blog-home-title {
    top: 20%;
    left: 15%;
    width: 70%;
    max-width: none;
  }

  .blog-home-title h1 {
    font-size: 60px;
  }

  .blog-home-title h2 {
    margin-top: 50px;
    font-size: 24px;
  }
  .background-div {
    height: unset;
    width: 100vw;
  }
  .background-div img {
    height: 100vh;
    width: inherit;
    overflow: hidden;
  }

  /* .home-button {
      position: absolute;
      right: 10%;
      top: 80%;
      margin-top: 0;
      z-index: 10;
      width: 200px;
      left: unset;
    } */
  .container-Home {
    height: 100vh;
    width: inherit;
    min-height: unset;
    overflow: hidden;
  }

  .submitForm {
    background: white;
    opacity: 0.8;
    border-radius: 5px;
    padding: 20px;
    transition: 0.5s;
    box-shadow: 0px 11px 16px 0px rgba(24, 30, 56, 0.2);
  }
}
