body {
  font-family: "Montserrat-medium";
}

.title {
  margin-top: 100;
  text-align: center;
}

.block {
  margin-top: 100px;
}

.blockPartenaire {
  flex-direction: column;
  text-align: right;
  padding: 20px;
  width: 200px;
  background-color: white;
  color: #f4be49;
}
.typePartenaire {
  width: 100vw;
  text-align: right;
  display: flex;
  flex-direction: row-reverse;

  border-radius: 10px;
}
