/* .container-Home {
  height: auto;
  overflow: unset;
  display: flex;
  min-height: 570px;
} */
body {
  font-family: "Montserrat-medium";
  /* background-image: url(../assets/backgroundEDLIntranet2-3.png); */
}

/* .ticket-EDL-home-title {
    color: #000;
  } */

.ticket-container-Home {
  /* overflow-y: hidden; */
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  /* height: 100vh; */
  width: inherit;
  /* overflow: hidden; */
}
.planning-submitForm {
  background: transparent;
  opacity: 0.8;
  border-radius: 5px;
  transition: 0.5s;
  /* box-shadow: 0px 11px 16px 0px rgba(24, 30, 56, 0.2); */
  color: black;
  /* box-shadow: 2px 2px 10px black; */
  display: flex;
  flex-direction: column;
  /* max-height: 50vh; */
  /* overflow-y: scroll; */
  margin: 20px;
  padding: 2%;
  text-align: center;
  font-size: 14px;
  margin-bottom: 2em;
  line-height: 30px;
  overflow-x: hidden;
  background-color: #ffffff78;
}

.planning-submitForm p {
  color: black;
  text-align: center;
  font-size: 14px;
  line-height: 30px;
}

.planning-submitForm textarea {
  max-width: 35vw;
  min-width: 80%;
  width: 100%;
  max-height: 300px;
  min-height: 100px;
  height: 100px;
  margin: auto;
  margin-bottom: 10px;
}

.planning-submitForm label {
  margin: 10px;
}

.planning-submitForm select {
  cursor: pointer;
  background-color: white;
  border-radius: 3px;
  border: none;
}

.planning-submitForm input,
textarea {
  border-radius: 3px;
  /* border: 1px solid black; */

  border: none;

  background-color: white;
}

#airtableIframe {
  width: 90%;
}

/* .submitForm textarea {
    border-radius: 3px;
    border: 1px solid #000;
  } */

.ticket-inputForm {
  width: 100%;
  margin: auto;
  margin-bottom: 10px;
}
#submitFormDateInput {
  cursor: pointer;
}

.formAlert {
  color: red;
}
.ticket-background-div {
  position: absolute;
  width: 100vw;
  min-height: 100vh;
  max-height: 880px;
  right: 0;
  top: 0;
  overflow: unset;
  z-index: 1;
  /* height: 100vh; */
  /* box-shadow: 1px 1px 20px grey; */
}

.ticket-background-img {
  width: 120%;
  height: 100%;
  opacity: 1;
  z-index: 0;
  box-shadow: 1px 1px 20px grey;
}

.ticket-blog-home-title {
  color: #000;
  display: flex;
  font-family: Montserrat-medium;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  text-shadow: 1px 5px 10px;
  width: 90%;
  z-index: 100;
}
.ticket-blog-home-title-div {
  border-bottom: 1px solid black;
}
.titles-tickets {
  margin: auto;
  margin-right: 20px;
  margin-left: 20px;
}
.ticket-blog-home-title p {
  font-size: 14px;
  font-weight: normal;
  text-align: unset;
  text-shadow: none;
  margin-bottom: 0;
}
.ticket-blog-home-title h1 {
  font-size: 40px;
  margin: 0;
  /* text-decoration: underline; */
  text-align: center;
  font-weight: 1;
  text-shadow: none;
  font-family: "Montserrat-medium";
}
.ticket-blog-home-title h2 {
  font-weight: 10;
  text-shadow: 2px 2px 10px black;
  text-align: center;
}

.footer {
  z-index: 2000;
  position: relative;
}

.subDivPlanning1 {
  width: 80%;
  margin: 5px;
  position: relative;
  text-align: center;
}
.subDivPlanning1-20 {
  width: 20%;
  margin: 5px;
}

.subDivPlanning2 {
  width: 20%;
  margin: 5px;
}

.subDivPlanning2-100 {
  width: 100%;
  margin: 5px;
}

.subDivPlanning2 select {
  cursor: pointer;
}
.subDivPlanning2 input {
  cursor: pointer;
}
.subDivPlanning2-ul {
  width: 100%;
  height: 250px;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}
.subDivPlanning2-100 input {
  cursor: pointer;
}
.subDivPlanning2-100 select {
  cursor: pointer;
}
.subDivPlanning2-100 ul {
  border-radius: 5px;
  background-color: aliceblue;
  cursor: pointer;
  margin: 2px;
  z-index: 150;
}

.subDivPlanning2-100 li {
  list-style: none;
  text-align: left;
  font-size: 14px;
  z-index: -1;
}

.add-members-div {
  background: #fff;
  border-radius: 5px;
  box-shadow: 1px 1px 10px #000;
  display: flex;
  flex-direction: column;
  left: calc(50% - 300px);
  position: absolute;
  top: calc(50% - 300px);
  width: 600px;
  z-index: 10001;
  height: 600px;
  text-align: center;
}

.big-grey-div {
  background: rgba(0, 0, 0, 0.3);
  display: block;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 10000;
}

.modify-resa-div-pl {
  background: white;
  display: none;
  /* height: 300px; */
  left: 50%;
  top: 0;
  position: absolute;
  /* top: calc(50vh - 300px); */
  width: 600px;
  flex-direction: column;
  z-index: 10001;
  box-shadow: 1px 1px 10px black;
  border-radius: 5px;
}
.modify-resa-div-pl label {
  margin-top: 10px;
}

.modify-resa-div-pl input {
  margin: 0px auto 20px;
  background-color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 1px 1px 10px gray;
}
.modify-resa-div-pl select {
  margin: 0px auto 20px;
  background-color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 1px 1px 10px gray;
}

#delete-resa {
  background: #f4af24;
}
table,
td {
  border: 1px solid #919191;
  /* background-color: aliceblue; */
  border-radius: 5px;
}

thead,
tfoot {
  background-color: aliceblue;
  color: black;
  margin-bottom: 10px;
}

/* .ticket-mid-home-div-logo {
    z-index: 10;
    position: relative;
    width: 30%;
  
    left: 5%;
  
    display: flex;
  }
  .mid-home-div-logo img {
    width: 100%;
    opacity: 0.25;
    margin: auto;
    max-width: 500px;
  } */

.ticket-btn-browse {
  font-size: 14px;
  font-weight: 200;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 16px;
  border-radius: 5px;
  transition: 0.5s;
  color: #fff;
  background: #f4af24;
  cursor: pointer;
  text-decoration: none;
  border: none;
}

.ticket-btn-browse:hover {
  /* background: rgb(163, 59, 59); */
  color: black;
  box-shadow: 1px 1px 10px black;
}

.dropZone {
  border: 2px dashed black;
  margin: 40px;
  transition: 0.5s;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
}

.dropZone img {
  height: 50px;
  z-index: -1000;
  position: relative;
}
.dropZone p {
  margin: 0;
  text-shadow: none;
}
#getFile {
  display: none;
}
#modif-resa {
  background-color: #f4af24;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 8px 16px;
  border-radius: 5px;
}
#modif-resa:hover {
  color: #000;
}
.btn-group {
  display: flex;
}
.btn-get-started {
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 16px;
  border-radius: 5px;
  transition: 0.5s;
  color: #fff;
  background: #f4af24;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  z-index: 100;
}
#ticket-btn-get-started {
  background: #f4af24;
  position: relative;
  left: calc(50% - 50px);
  width: 100px;
  text-align: center;
  border: none;
  z-index: 100;
}

.btn-get-started:hover {
  color: black;
  /* box-shadow: 1px 1px 10px rgb(7, 7, 7); */
}

.drop-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 800px;
  height: 200px;
  border: 4px dashed #4aa1f3;
}

#spinner-modal {
  /* display: none; */
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.2) url("../assets/spinner.gif") 50% 50% no-repeat;
}

#loading-long-text {
  position: fixed;
  top: 55%;
  z-index: 2001;
  margin: auto;
  left: calc(50% - 150px);
  width: 300px;
  font-size: 30px;
  text-shadow: 5px 5px 15px #000;
  opacity: 0;
  color: #fff;
}

#backToPortail {
  z-index: 1000;
}

#containerMyTickets {
  font-family: "Montserrat-medium";
  z-index: 100;
  position: relative;
}

.ulTicket {
  display: flex;
  flex-direction: column;
}
.ticketList {
  width: 50%;
  margin: auto;
  text-align: left;
  list-style: none;
  cursor: pointer;
}

.ticket-subDivForm1 {
  display: flex;
  width: 100%;
}
.ticket-subDivForm2 {
  width: 45%;
  margin: auto;
  margin-right: 10px;
  margin-left: 10px;
}
.ticket-subDivForm2 p {
  font-size: 14px;
  font-weight: normal;
  text-align: unset;
  text-shadow: none;
  margin-bottom: 0;
}

.planning {
  z-index: 2000;
  margin-right: 5%;
  width: 90%;
  margin-left: 5%;
  display: flex;
}

@font-face {
  font-family: "Kollectif";
  src: url(../fonts/kollektif/Kollektif.ttf); /* IE9 Compat Modes */
  font-display: swap;
}
@font-face {
  font-family: "Montserrat-medium";
  src: url(../fonts/Montserrat/static/Montserrat-Medium.ttf); /* IE9 Compat Modes */
  font-display: swap;
}
@font-face {
  font-family: "DoHyeon";
  src: url(../fonts/Do_Hyeon/DoHyeon-Regular.ttf); /* IE9 Compat Modes */
  font-display: swap;
}

@media only screen and (max-width: 1160px) {
  .mid-home-div-logo {
    position: absolute;
    top: 15%;
  }
  .blog-home-title {
    left: 40%;
  }

  .blog-home-title h1 {
    font-size: 80px;
  }
}

@media only screen and (max-width: 650px) {
  .mid-home-div-logo {
    display: none;
  }
  .ticket-blog-home-title {
    top: 20%;
    /* left: 15%; */
    width: 90%;
    max-width: none;
    flex-direction: column;
  }

  .blog-home-title h1 {
    font-size: 60px;
  }

  .blog-home-title h2 {
    margin-top: 50px;
    font-size: 24px;
  }
  .titles-tickets {
    width: 90%;
  }
  .planning-submitForm {
    width: 100%;
  }
  .ticket-background-img {
    display: none;
  }
  .background-div {
    height: 100vh;
    width: inherit;
  }
  .background-div img {
    height: 100vh;
    width: inherit;
    overflow: hidden;
  }

  /* .home-button {
        position: absolute;
        right: 10%;
        top: 80%;
        margin-top: 0;
        z-index: 10;
        width: 200px;
        left: unset;
      } */
  .container-Home {
    height: 100vh;
    width: inherit;
    overflow: unset;
    display: flex;
    min-height: 570px;
  }
  .ticket-blog-home-title h1 {
    font-size: 40px;
    margin: 0;
    margin-top: 50px;
  }
  .ticket-blog-home-title h2 {
    font-size: 14px;
  }

  .ticket-submitForm {
    width: unset;
  }

  .ticket-submitForm input {
    border: 1px solid gray;
  }
  .ticket-submitForm textarea {
    border: 1px solid gray;
  }
  .ticket-submitForm select {
    border: 1px solid gray;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .mid-home-div-logo {
    display: none;
  }
  .blog-home-title {
    top: 20%;
    left: 15%;
    width: 70%;
    max-width: none;
  }

  .blog-home-title h1 {
    font-size: 60px;
  }

  .blog-home-title h2 {
    margin-top: 50px;
    font-size: 24px;
  }
  .background-div {
    height: unset;
    width: 100vw;
  }
  .background-div img {
    height: 100vh;
    width: inherit;
    overflow: hidden;
  }

  /* .home-button {
        position: absolute;
        right: 10%;
        top: 80%;
        margin-top: 0;
        z-index: 10;
        width: 200px;
        left: unset;
      } */
  .container-Home {
    height: 100vh;
    width: inherit;
    min-height: unset;
    overflow: hidden;
  }

  .submitForm {
    background: white;
    opacity: 0.8;
    border-radius: 5px;
    padding: 20px;
    transition: 0.5s;
    box-shadow: 0px 11px 16px 0px rgba(24, 30, 56, 0.2);
  }
}

#city {
  background-color: #fff;
  border: none;
  border-radius: 3px;
  height: 30px;
  max-width: 50%;
  font-weight: 600;
}

option:hover {
  background-color: #f4af24;
  color: #fff;
}

label {
  margin-right: 10px;
}

ul {
  padding: 10px;
}
ul:hover {
  background-color: #f4af24;
  color: #fff;
}

body {
  background-image: url(../assets/backgroundEDLIntranet2-3.png);
}

.lieu {
  text-align: center;
  font-size: 80px;
}

.vuesalle {
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  text-decoration: none;
  color: #f4af24;
  margin-bottom: 10px;
  margin-left: 10px;
}

.voirsalle {
  display: flex;
}
