.custom-card {
  z-index: 100;
  max-width: 400px;
  padding: 20px;
  box-shadow: 0 1px 3px 3px #0000001a;
  border-radius: 4px;
  margin: 0px auto;
  background-color: white;
  overflow-y: auto;
}
.custom-card .div-item {
  margin: 10px 0 20px;
  text-align: left;
}
.custom-card input {
  display: inline-block;
  min-height: 40px;
  width: calc(100% - 24px);
  font-size: 14px;
  line-height: 1.8;
  padding: 6px 12px;
  vertical-align: middle;
  background-color: transparent;
  color: #333;
  border: 1px solid #ddd;
}
