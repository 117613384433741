.container {
  color: #000;
  display: flex;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 50px;
  margin-top: 0;
  text-shadow: 1px 5px 10px;
  width: 90%;
  z-index: 100;
}

.sub-container {
  margin-top: 50px;
  min-width: 200px;
  padding: 10px;
}

@font-face {
  font-family: "Kollectif";
  src: url(../../fonts/kollektif/Kollektif.ttf); /* IE9 Compat Modes */
  font-display: swap;
}
@font-face {
  font-family: "Montserrat-medium";
  src: url(../../fonts/Montserrat/static/Montserrat-Medium.ttf); /* IE9 Compat Modes */
  font-display: swap;
}
