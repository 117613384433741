body{
  font-family: "Montserrat-medium";
}

.container-Home {
  height: auto;
  /* overflow: hidden; */
  display: flex;
  min-height: 570px;
  flex-direction: column;
  /* background-image: url("../media/backgroundEDLIntranet2-1.8ee47eb4bba4ed8305ae.png"); */
}

.container-Home h2 {
  z-index: 10;
  text-align: left;
  margin: 20px 15% 10px;
  text-decoration: underline;
  text-shadow: 1px 1px 10px white;
}
.container-Home h3 {
  z-index: 10;
  text-align: left;
  margin: 20px 5% 10px;
  text-shadow: 1px 1px 10px white;
  position: relative;
  top: 10px;
  left: 10px;
}

.background-div {
  position: absolute;
  width: 100%;
  min-height: 570px;
  right: 0;
  top: 0;
  overflow: hidden;
  z-index: 1;
  height: 100%;
}

.background-img {
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 0;
}

.EDL-home-title {
  z-index: 100;
  font-family: "Montserrat-medium";
  color: black;
  margin-bottom: 0;
  margin-top: 50px;
  margin-right: auto;
  margin-left: auto;
  width: 60%;
}
.EDL-home-title-div {
  border-bottom: 1px solid black;
}
.EDL-home-title p {
  font-size: 20px;
  font-weight: 2;
  text-align: center;
}
.EDL-home-title h1 {
  font-size: 80px;
  margin: 0;
  /* text-decoration: underline; */
  text-align: center;
  font-weight: 1;
}
.EDL-home-title h2 {
  font-weight: 10;
  text-shadow: 2px 2px 10px;
  text-align: center;
}

.flex-container-home {
  display: flex;
  flex-wrap: wrap;
  max-width: 90%;
  justify-content: space-between;
  height: auto;
  margin-right: auto;
  margin-left: auto;
}

.flex-subcontainer-home {
  display: flex;
  /* width: 350px; */
  /* margin: auto; */
  padding: 10px;
}

.flex-vertical-subcontainer-home {
  display: block;
  min-height: 140px;
}

.mid-home-div-logo {
  z-index: 10;
  position: relative;
  width: 30%;

  /* top: 20%; */
  left: 5%;

  display: flex;
}
.mid-home-div-logo img {
  width: 100%;
  opacity: 0.25;
  margin: auto;
  max-width: 500px;
}

.home-button {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  margin-top: 0;
  margin-bottom: 40px;
  z-index: 101;
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.btn-get-started-home {
  background-color: #f4af24cc;
  border-radius: 5px;
  box-shadow: 1px 1px 10px gray;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 300;
  /* height: 250px; */
  letter-spacing: 1px;
  margin: auto;
  padding: 8px;
  text-decoration: none;
  text-shadow: 1px 1px 3px gray;
  text-transform: uppercase;
  transition: 0.4s;
  width: 360px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 90px;
}

.btn-get-started-home img {
  height: 80px;
  width: 80px;
  margin: auto;
}
.btn-get-started-home h4 {
  margin: auto;
  margin-left: 10px;
  width: 130px;
  font-size: 14px;
}

.btn-get-started-home p {
  font-size: 11px;
  font-weight: 200;
  text-transform: initial;
  letter-spacing: unset;
  padding: 8px 8px;
  color: white;
  text-align: left;
  margin: auto;
  width: 140px;
  margin-left: 10px;
}

.btn-get-started-home:hover {
  color: black;
  box-shadow: 1px 1px 20px gray;
}

@font-face {
  font-family: "Kollectif";
  src: url(../fonts/kollektif/Kollektif.ttf); /* IE9 Compat Modes */
  font-display: swap;
}
@font-face {
  font-family: "Montserrat-medium";
  src: url(../fonts/Montserrat/static/Montserrat-Medium.ttf); /* IE9 Compat Modes */
  font-display: swap;
}
@font-face {
  font-family: "Montserrat-medium";
  src: url(../fonts/Montserrat/static/Montserrat-Medium.ttf); /* IE9 Compat Modes */
  font-display: swap;
}

@media only screen and (max-width: 1160px) {
  .mid-home-div-logo {
    position: absolute;
    top: 15%;
  }
  .blog-home-title {
    left: 40%;
  }

  .blog-home-title h1 {
    font-size: 80px;
  }
}

@media only screen and (max-width: 650px) {
  .mid-home-div-logo {
    display: none;
  }
  .blog-home-title {
    top: 20%;
    /* left: 15%; */
    width: 70%;
    max-width: none;
  }

  .blog-home-title h1 {
    font-size: 60px;
  }

  .blog-home-title h2 {
    margin-top: 50px;
    font-size: 24px;
  }
  .background-div {
    height: 100vh;
    width: inherit;
  }
  .background-div img {
    height: 100vh;
    width: inherit;
    overflow: hidden;
  }

  .home-button {
    flex-direction: column;
  }

  .btn-get-started {
    margin: 10px;
  }
  .container-Home {
    height: 100vh;
    width: inherit;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .mid-home-div-logo {
    display: none;
  }
  .blog-home-title {
    top: 20%;
    left: 15%;
    width: 70%;
    max-width: none;
  }

  .blog-home-title h1 {
    font-size: 60px;
  }

  .blog-home-title h2 {
    margin-top: 50px;
    font-size: 24px;
  }
  .background-div {
    height: unset;
    width: 100vw;
  }
  .background-div img {
    height: 100vh;
    width: inherit;
    overflow: hidden;
  }

  /* .home-button {
    position: absolute;
    right: 10%;
    top: 80%;
    margin-top: 0;
    z-index: 10;
    width: 200px;
    left: unset;
  } */
  .container-Home {
    height: 100vh;
    width: inherit;
    min-height: unset;
  }
}

.button-alone {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 20px;
}
.img-edl {
  width: 200px;
  height: 100px;
}
