/* .container-Home {
  height: 100vh;
  overflow: unset;
  display: flex;
  min-height: 570px;
} */
.facture-container-Home {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  width: inherit;
}
/* .submitForm {
  background: aliceblue;
  opacity: 0.8;
  border-radius: 5px;
  padding: 20px;
  transition: 0.5s;
  color: black;
  box-shadow: 2px 2px 10px black;
  display: flex;
  flex-direction: column;
  max-height: 50vh;
  overflow-y: scroll;
}

.submitForm label {
  margin: 10px;
} */

.facture-submitForm {
  background: transparent;
  opacity: 0.8;
  border-radius: 5px;
  padding: 20px;
  transition: 0.5s;
  /* box-shadow: 0px 11px 16px 0px rgba(24, 30, 56, 0.2); */
  color: black;
  /* box-shadow: 2px 2px 10px black; */
  display: flex;
  flex-direction: column;
  /* max-height: 50vh; */
  /* overflow-y: scroll; */
  margin: 20px;
  padding: 5%;
  text-align: left;
  font-size: 14px;
  margin-bottom: 2em;
  line-height: 30px;
  overflow-x: hidden;
  font-family: "Montserrat-medium";
}
.facture-submitForm p {
  color: black;
  text-align: left;
  font-size: 14px;
  line-height: 30px;
}

.facture-submitForm textarea {
  max-width: 35vw;
  min-width: 80%;
  width: 100%;
  max-height: 300px;
  min-height: 100px;
  height: 100px;
  margin: auto;
  margin-bottom: 10px;
}

.facture-submitForm label {
  margin: 10px;
}

.facture-submitForm select {
  cursor: pointer;
  background-color: white;
  border-radius: 3px;
  border: none;
}

.facture-submitForm input,
textarea {
  border-radius: 3px;
  /* border: 1px solid black; */

  border: none;

  background-color: white;
}
#submitFormDateInput {
  cursor: pointer;
}

.formAlert {
  color: red;
}
#formAlert {
  color: red;
}
/* .background-div {
  position: absolute;
  width: 100%;
  min-height: 570px;
  right: 0;
  top: 0;
  overflow: hidden;
  z-index: 1;
  height: 100%;
} */

.facture-background-div {
  position: absolute;
  width: 100vw;
  min-height: 100vh;
  max-height: 880px;
  height: 100vh;
  right: 0;
  top: 0;
  overflow: hidden;
  overflow-y: clip;
  z-index: 1;
  /* height: 100vh; */
  /* box-shadow: 1px 1px 20px grey; */
}

/* .background-img {
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 0;
} */

.facture-background-img {
  width: 120%;
  /* height: 100%; */
  opacity: 1;
  z-index: 0;
  box-shadow: 1px 1px 20px grey;
}
.titles-facture {
  margin: auto;
  margin-right: 20px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  width: 45%;
}
.blog-home-title {
  z-index: 100;
  /* position: absolute;
    top: 20%; */
  font-family: "Montserrat-medium";
  color: black;
  /* text-align: left; */
  /* max-width: 60%; */
  margin: auto;
  width: 90%;
  display: flex;
}
.blog-home-title-div {
  border-bottom: 1px solid black;
}
.blog-home-title p {
  font-size: 16px;
  font-weight: 1;
  text-align: center;
  text-shadow: none;
}
.blog-home-title h1 {
  font-size: 80px;
  margin: 0;
  /* text-decoration: underline; */
  font-family: "Montserrat-medium";

  text-align: center;
  font-weight: 1;
  /* text-shadow: 2px 2px 10px black; */
}
.blog-home-title h2 {
  font-weight: 10;
  /* text-shadow: 2px 2px 10px black; */
  text-align: center;
}

.mid-home-div-logo {
  z-index: 10;
  position: relative;
  width: 30%;

  /* top: 20%; */
  left: 5%;

  display: flex;
}
.mid-home-div-logo img {
  width: 100%;
  opacity: 0.25;
  margin: auto;
  max-width: 500px;
}

.facture-right-block {
  width: 45%;
  margin: auto;
  max-height: 80vh;
  overflow-y: auto;
}

.btn-browse {
  font-size: 14px;
  font-weight: 200;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 16px;
  border-radius: 5px;
  transition: 0.5s;
  color: #fff;
  background: #f4af24;
  cursor: pointer;
  text-decoration: none;
  border: none;
}

.btn-browse:hover {
  /* background: rgb(163, 59, 59); */
  color: black;
  box-shadow: 1px 1px 10px white;
}

.dropZone {
  border: 1px dashed blue;
  margin: 40px;
  transition: 0.5s;
  border-radius: 5px;
  padding: 10px;
}

.dropZone img {
  height: 50px;
  z-index: -1000;
  position: relative;
}
.dropZone p {
  margin: 0;
  text-shadow: none;
}
#getFile {
  display: none;
}

.btn-get-started {
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 16px;
  border-radius: 5px;
  transition: 0.5s;
  color: #fff;
  background: #f4af24;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  z-index: -1000;
}

.btn-get-started:hover {
  color: black;
  /* box-shadow: 1px 1px 10px rgb(7, 7, 7); */
}

#facture-btn-get-started {
  background: #f4af24;
  position: relative;
  width: 100px;
  left: calc(50% - 50px);
  text-align: center;
  border: none;
}
#sendToAirtable {
  background: #f4af24;
  position: relative;
  /* left: calc(50% - 50px); */
  width: 100px;
  text-align: center;
  border: none;
}

.drop-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 800px;
  height: 200px;
  border: 4px dashed #4aa1f3;
}

#spinner-modal {
  /* display: none; */
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.2) url("../assets/spinner.gif") 50% 50% no-repeat;
}

#loading-long-text {
  position: fixed;
  top: 55%;
  z-index: 2001;
  margin: auto;
  left: calc(50% - 150px);
  width: 300px;
  font-size: 30px;
  text-shadow: 5px 5px 15px #000;
  opacity: 0;
  color: #fff;
}

#backToPortail {
  z-index: 1000;
}

@font-face {
  font-family: "Kollectif";
  src: url(../fonts/kollektif/Kollektif.ttf); /* IE9 Compat Modes */
  font-display: swap;
}
@font-face {
  font-family: "Montserrat-medium";
  src: url(../fonts/Montserrat/static/Montserrat-Medium.ttf); /* IE9 Compat Modes */
  font-display: swap;
}
@font-face {
  font-family: "DoHyeon";
  src: url(../fonts/Do_Hyeon/DoHyeon-Regular.ttf); /* IE9 Compat Modes */
  font-display: swap;
}

@media only screen and (max-width: 1160px) {
  .mid-home-div-logo {
    position: absolute;
    top: 15%;
  }
  .blog-home-title {
    left: 40%;
  }

  .blog-home-title h1 {
    font-size: 80px;
  }
}

@media only screen and (max-width: 650px) {
  .mid-home-div-logo {
    display: none;
  }
  .blog-home-title {
    top: 20%;
    /* left: 15%; */
    width: 100%;
    max-width: none;
    flex-direction: column;
    margin: 80px auto;
  }

  .blog-home-title h1 {
    font-size: 60px;
  }

  .blog-home-title h2 {
    margin-top: 50px;
    font-size: 24px;
  }
  .titles-facture {
    width: 90%;
  }
  .facture-right-block {
    width: 100%;
  }
  .facture-background-img {
    display: none;
  }
  .background-div {
    height: 100vh;
    width: inherit;
  }
  .background-div img {
    height: 100vh;
    width: inherit;
    overflow: hidden;
  }

  /* .home-button {
    position: absolute;
    right: 10%;
    top: 80%;
    margin-top: 0;
    z-index: 10;
    width: 200px;
    left: unset;
  } */
  .container-Home {
    height: 100vh;
    width: inherit;
    overflow: hidden;
    height: 100vh;
    overflow-y: scroll;
  }
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .mid-home-div-logo {
    display: none;
  }
  .blog-home-title {
    top: 20%;
    left: 15%;
    width: 70%;
    max-width: none;
  }

  .blog-home-title h1 {
    font-size: 60px;
  }

  .blog-home-title h2 {
    margin-top: 50px;
    font-size: 24px;
  }
  .background-div {
    height: unset;
    width: 100vw;
  }
  .background-div img {
    height: 100vh;
    width: inherit;
    overflow: hidden;
  }

  /* .home-button {
    position: absolute;
    right: 10%;
    top: 80%;
    margin-top: 0;
    z-index: 10;
    width: 200px;
    left: unset;
  } */
  .container-Home {
    height: 100vh;
    width: inherit;
    min-height: unset;
  }

  .submitForm {
    background: aliceblue;
    opacity: 0.8;
    border-radius: 5px;
    padding: 20px;
    transition: 0.5s;
    box-shadow: 0px 11px 16px 0px rgba(24, 30, 56, 0.2);
  }
}
