/* .container-Home {
  height: auto;
  overflow: unset;
  display: flex;
  min-height: 570px;
} */
body {
  font-family: "Montserrat-medium";
  background-image: none;
}

/* .ticket-EDL-home-title {
    color: #000;
  } */

.faq-container {
  margin-top: 70px;
  text-align: center;
}

ul:hover {
  background-color: unset;
  color: unset;
}

ul {
  padding-left: 40px;
}

p {
  text-align: justify;
}
.faq-title p,
.faq-select p {
  text-align: center;
}
.faq-content {
  text-align: justify;
  margin: auto;
  width: 80%;
  border-right: 2px #f4af24 solid;
  border-left: 2px #f4af24 solid;
  padding: 20px;
}

.faq-detail ul li {
  margin-bottom: 15px;
}

.faq-select {
  margin: 50px 0;
}

.faq-select select {
  cursor: pointer;
  background-color: white;
  border-radius: 50px;
  border: 2px solid gray;
  width: 25%;
  box-shadow: 1px 1px 10px #80808070;
}

.three-color-separator {
  padding-bottom: 1px;
  content: "";
  height: 1px;
  position: relative;
  bottom: -10px;
  background: linear-gradient(
    to right,
    rgba(237, 128, 52, 1) 0%,
    rgba(237, 128, 52, 1) 33%,
    rgba(254, 177, 35, 1) 33%,
    rgba(254, 177, 35, 1) 66%,
    rgba(33, 132, 205, 1) 66%,
    rgba(33, 132, 205, 1) 100%
  );
  width: 25%;
  margin: 0 auto;
}

.align-vertical-img {
  vertical-align: middle;
  border: 1px gray solid;
  border-radius: 3px;
  max-width: 100%;
}

.align-horizontal-img {
  display: block;
  margin: 10px auto 20px;
  border: 1px gray solid;
  border-radius: 3px;
  max-width: 100%;
}

/* img {
  vertical-align: middle;
  border: 1px gray solid;
  border-radius: 3px;
} */

.faq-details code iframe,
iframe {
  margin: 10px 10%;;
  position: relative;
  display: inline-block;
  width: 80%;
}
@font-face {
  font-family: "Kollectif";
  src: url(../fonts/kollektif/Kollektif.ttf); /* IE9 Compat Modes */
  font-display: swap;
}
@font-face {
  font-family: "DoHyeon";
  src: url(../fonts/Do_Hyeon/DoHyeon-Regular.ttf); /* IE9 Compat Modes */
  font-display: swap;
}

@media only screen and (max-width: 1160px) {
}

@media only screen and (max-width: 650px) {
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
}
